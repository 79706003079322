import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from "prop-types"

const BackgroundSection = ({ className, imageData, children, height }) => (


    <StyledBackgroundSection
      Tag="section"
      className={className}
      fluid={imageData}
      style={{height: height}}
    >
      {children}
    </StyledBackgroundSection>  
);


const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  display: flex;
`;

export default BackgroundSection


BackgroundSection.defaultProps = {
    height: `400px`
};

BackgroundSection.propTypes = {
    height: PropTypes.string,
    imageData: PropTypes.object
};